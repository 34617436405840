import React from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './hero.css'

const Hero = (props) => {
  return (
    <div id="port" className="hero-header78">
      <div className="hero-column thq-section-max-width thq-section-padding">
        <div className="hero-content">
          <h1 className="hero-text thq-heading-1">{props.heading1}</h1>
          <p className="hero-text1 thq-body-large">{props.content1}</p>
        </div>
        <div className="hero-actions">
          <a
            href="https://www.instagram.com/a.s_millwork/"
            target="_blank"
            rel="noreferrer noopener"
            className="hero-button thq-button-filled"
          >
            <span className="thq-body-small">{props.action1}</span>
          </a>
          <a
            href="tel:+14375804280"
            className="hero-button1 thq-button-outline"
          >
            <span className="thq-body-small">{props.action2}</span>
          </a>
        </div>
      </div>
      <div className="hero-content1">
        <div className="hero-row-container thq-animated-group-container-horizontal thq-mask-image-horizontal">
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/4.jpeg?alt=media&amp;token=96a6eb7a-c876-45cb-8372-92b1e90a3e0d"
              loading="eager"
              className="hero-placeholder-image thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image2Alt}
              src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w10.jpeg?alt=media&amp;token=6aa4ebf1-1d9c-47c7-995d-bc49fd643e30"
              loading="eager"
              className="hero-placeholder-image01 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image3Alt}
              src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w12.jpeg?alt=media&amp;token=17fe2b77-03a9-4b7f-acb2-0c2a5a9623ad"
              loading="eager"
              className="hero-placeholder-image02 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="hero-placeholder-image03 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="hero-placeholder-image04 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image6Alt}
              src={props.image6Src}
              className="hero-placeholder-image05 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="hero-placeholder-image06 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="hero-placeholder-image07 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="hero-placeholder-image08 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="hero-placeholder-image09 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="hero-placeholder-image10 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1534312527009-56c7016453e6?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="hero-placeholder-image11 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
        </div>
        <div className="hero-row-container1 thq-animated-group-container-horizontal thq-mask-image-horizontal">
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w13.jpeg?alt=media&amp;token=c3a872e5-1915-4d62-aff2-63fc574221bb"
              loading="eager"
              className="hero-placeholder-image12 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image8Alt}
              src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w14.jpeg?alt=media&amp;token=ebbe52d1-4801-47b4-b763-74112c5249ec"
              loading="eager"
              className="hero-placeholder-image13 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image9Alt}
              src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w15.jpeg?alt=media&amp;token=50fe25c1-ca4d-452f-a45c-3c39c46db9e0"
              loading="eager"
              className="hero-placeholder-image14 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image10Alt}
              src={props.image10Src}
              className="hero-placeholder-image15 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image11Alt}
              src={props.image11Src}
              className="hero-placeholder-image16 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image12Alt}
              src={props.image12Src}
              className="hero-placeholder-image17 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src={props.image7Src}
              className="hero-placeholder-image18 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image8Alt}
              src={props.image8Src}
              className="hero-placeholder-image19 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image9Alt}
              src={props.image9Src}
              className="hero-placeholder-image20 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image10Alt}
              src={props.image10Src}
              className="hero-placeholder-image21 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image11Alt}
              src={props.image11Src}
              className="hero-placeholder-image22 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1568214379698-8aeb8c6c6ac8?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHxncmFmaWN8ZW58MHx8fHwxNzE1Nzk0OTk5fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="hero-placeholder-image23 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="hero-container1">
          <React.Fragment>
            <React.Fragment>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '\n  @keyframes scroll-x {\n    from {\n      transform: translateX(0);\n    }\n    to {\n      transform: translateX(calc(-100% - 16px));\n    }\n  }\n\n  @keyframes scroll-y {\n    from {\n      transform: translateY(0);\n    }\n    to {\n      transform: translateY(calc(-100% - 16px));\n    }\n  }\n',
                }}
              />
            </React.Fragment>
          </React.Fragment>
        </div>
      </div>
    </div>
  )
}

Hero.defaultProps = {
  image3Src:
    'https://images.unsplash.com/photo-1525498128493-380d1990a112?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTcxNDI0N3w&ixlib=rb-4.0.3&q=80&w=1080',
  image5Alt: 'Custom Cabinets in Dining Room',
  image1Src:
    'https://images.unsplash.com/photo-1508873699372-7aeab60b44ab?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTcxNDI0OHw&ixlib=rb-4.0.3&q=80&w=1080',
  content1:
    'Transform your living space with our beautifully crafted custom cabinets. Our expert craftsmen create unique designs tailored to your style and needs.',
  heading1: 'Exquisite Custom Cabinets for Your Home',
  image8Src:
    'https://images.unsplash.com/photo-1507238691740-187a5b1d37b8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTcxNDI0OHw&ixlib=rb-4.0.3&q=80&w=1080',
  image4Src:
    'https://images.unsplash.com/photo-1471520201477-47a62a269a87?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTcxNDI0OHw&ixlib=rb-4.0.3&q=80&w=1080',
  image11Alt: 'Custom Cabinets in Walk-in Closet',
  image12Src:
    'https://images.unsplash.com/photo-1545239351-1141bd82e8a6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTcxNDI0OHw&ixlib=rb-4.0.3&q=80&w=1080',
  image5Src:
    'https://images.unsplash.com/photo-1538688273852-e29027c0c176?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTcxNDI0OHw&ixlib=rb-4.0.3&q=80&w=1080',
  image7Alt: 'Custom Cabinets in Entertainment Room',
  image1Alt: 'Custom Cabinets in Living Room',
  action1: 'View Our Portfolio',
  image9Alt: 'Custom Cabinets in Garage',
  image4Alt: 'Custom Cabinets in Bedroom',
  image9Src:
    'https://images.unsplash.com/photo-1424298397478-4bd87a6a0f0c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTcxNDI0OHw&ixlib=rb-4.0.3&q=80&w=1080',
  image11Src:
    'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTcxNDI0N3w&ixlib=rb-4.0.3&q=80&w=1080',
  image10Src:
    'https://images.unsplash.com/photo-1483794344563-d27a8d18014e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTcxNDI0OHw&ixlib=rb-4.0.3&q=80&w=1080',
  image2Alt: 'Custom Kitchen Cabinets',
  action2: 'Get a Free Quote',
  image2Src:
    'https://images.unsplash.com/photo-1474557157379-8aa74a6ef541?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTcxNDI0N3w&ixlib=rb-4.0.3&q=80&w=1080',
  image6Src:
    'https://images.unsplash.com/photo-1483137140003-ae073b395549?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTcxNDI0N3w&ixlib=rb-4.0.3&q=80&w=1080',
  image7Src:
    'https://images.unsplash.com/photo-1443916568596-df5a58c445e9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTcxNDI0N3w&ixlib=rb-4.0.3&q=80&w=1080',
  image3Alt: 'Custom Bathroom Cabinets',
  image8Alt: 'Custom Cabinets in Laundry Room',
  image6Alt: 'Custom Office Cabinets',
  image10Alt: 'Custom Cabinets in Pantry',
  image12Alt: 'Custom Cabinets in Home Office',
}

Hero.propTypes = {
  image3Src: PropTypes.string,
  image5Alt: PropTypes.string,
  image1Src: PropTypes.string,
  content1: PropTypes.string,
  heading1: PropTypes.string,
  image8Src: PropTypes.string,
  image4Src: PropTypes.string,
  image11Alt: PropTypes.string,
  image12Src: PropTypes.string,
  image5Src: PropTypes.string,
  image7Alt: PropTypes.string,
  image1Alt: PropTypes.string,
  action1: PropTypes.string,
  image9Alt: PropTypes.string,
  image4Alt: PropTypes.string,
  image9Src: PropTypes.string,
  image11Src: PropTypes.string,
  image10Src: PropTypes.string,
  image2Alt: PropTypes.string,
  action2: PropTypes.string,
  image2Src: PropTypes.string,
  image6Src: PropTypes.string,
  image7Src: PropTypes.string,
  image3Alt: PropTypes.string,
  image8Alt: PropTypes.string,
  image6Alt: PropTypes.string,
  image10Alt: PropTypes.string,
  image12Alt: PropTypes.string,
}

export default Hero
