import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Hero from '../components/hero'
import Features1 from '../components/features1'
import CTA from '../components/cta'
import Features2 from '../components/features2'
import Steps from '../components/steps'
import Testimonial from '../components/testimonial'
import Contact from '../components/contact'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>ASM Millwork</title>
      </Helmet>
      <Navbar></Navbar>
      <Hero
        action1="Explore Our Portfolio"
        action2="Request Your Free Quote"
        content1="Reimagine Your Living Space with Our Handcrafted Custom Cabinets. Our Skilled Artisans Design Unique, Tailor-Made Solutions to Match Your Style and Needs."
        heading1="Elevate Your Home with Exquisite Custom Cabinets"
        image1Src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w7.jpeg?alt=media&amp;token=a8ab12e2-2b1d-4286-9a49-31f3698ea568"
        image2Src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w8.jpeg?alt=media&amp;token=3b63877b-2084-49e7-8b78-83429dfe0b2c"
        image3Src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w9.jpeg?alt=media&amp;token=17d7be63-15c1-4876-89b7-16d5375f3fee"
        image4Src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w22.jpeg?alt=media&amp;token=86d316ba-284c-458a-82f4-1ab6e9991579"
        image5Src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/photo_2024-06-30_00-41-30.jpg?alt=media&amp;token=6a4d270b-27a9-41f4-ae91-906938d1a12a"
        image6Src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/photo_2024-06-30_00-41-41.jpg?alt=media&amp;token=6ed86903-71ee-4ede-b145-1cde590f7b82"
        image7Src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/photo_2024-06-30_00-41-57.jpg?alt=media&amp;token=5b28a4e6-dee7-4630-923a-abc2b2d7c9c5"
        image8Src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/photo_2024-06-30_00-41-47.jpg?alt=media&amp;token=c064f9e1-ae48-4324-bf92-bce55ee26be8"
        image9Src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/photo_2024-06-30_00-42-03.jpg?alt=media&amp;token=48b5e302-125e-44ac-b2e9-815e54dfe213"
        image10Src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w3.jpeg?alt=media&amp;token=a35e3a87-e6de-44ca-bfa0-c76b7eed33fe"
        image11Src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w4.jpeg?alt=media&amp;token=0c69e292-5505-43d6-aff4-ffc16fbd3c9f"
        image12Src="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w5.jpeg?alt=media&amp;token=f1aeee43-654c-4fd4-a70a-08d5285c1276"
      ></Hero>
      <Features1
        feature2ImgSrc="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w19.jpeg?alt=media&amp;token=9b403d9a-7a83-472c-aeb8-67e725b18d22"
        feature3ImgSrc="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w17.jpeg?alt=media&amp;token=f258f7cd-29fe-4775-b462-d0d84f658ab6"
        feature1Description="Tailored Designs: Our custom cabinets are crafted to seamlessly fit and enhance your unique space."
        feature2Description="Unmatched Craftsmanship: Our expert artisans ensure every cabinet is a masterpiece of quality and precision."
      ></Features1>
      <CTA rootClassName="cta-root-class-name"></CTA>
      <Features2
        feature1Title="Bespoke Cabinet Solutions"
        feature2Title="Exquisite Craftsmanship"
        feature3Title="On-Time Delivery Commitment"
        feature1ImgSrc="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w2.jpeg?alt=media&amp;token=371815e8-d652-42e4-a339-0aeb75647f34"
        feature2ImgSrc="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w20.jpeg?alt=media&amp;token=93c7a6bd-273e-427c-af1e-ef2ca6a1b56c"
        feature3ImgSrc="https://firebasestorage.googleapis.com/v0/b/asm-millwork.appspot.com/o/w21.jpeg?alt=media&amp;token=a88e7a64-4f72-4ae4-8369-5acc6736df25"
      ></Features2>
      <Steps></Steps>
      <Testimonial
        review1="ASM Millwork transformed our office space with their custom cabinetry. The precision and artistry in their work are unmatched. Highly recommended!"
        review2="Working with ASM Millwork was a designer's dream. Their expertise in crafting bespoke cabinets elevated the aesthetic of our client's home."
        review3="ASM Millwork delivered beyond my expectations with the cabinets in my kitchen. Their attention to detail and commitment to quality are exceptional."
        review4="As an architect, I appreciate craftsmanship. ASM Millwork not only met but exceeded my standards with their precise execution and beautiful designs."
        content1="I engaged ASM Millwork for a custom project, and I was thoroughly impressed by the impeccable quality of their craftsmanship. The attention to detail surpassed my expectations, resulting in truly exceptional cabinets"
        author1Name="Mark Thompson"
        author2Name="Jessica Ramirez"
        author3Name="David Chang"
        author4Name="Linda Robinson"
        author1Position="Homeowner"
        author2Position="Interior Design Enthusiast"
        author3Position="Business Owner"
        author4Position="Home Renovation Enthusiast"
      ></Testimonial>
      <Contact
        email1="Asm.millwork@gmail.com"
        phone1="+1 (437) 580-4280"
        content1="Call us now and let ASM Millwork bring magic into your home"
        content2="We are ready to provide service 24/7"
        content4="Call us right now for instant Quote"
      ></Contact>
      <Footer></Footer>
    </div>
  )
}

export default Home
