import React from 'react'

import PropTypes from 'prop-types'

import './steps.css'

const Steps = (props) => {
  return (
    <div id="service" className="steps-container thq-section-padding">
      <div className="steps-max-width thq-section-max-width">
        <div className="steps-container1 thq-grid-2">
          <div className="steps-section-header">
            <h2 className="thq-heading-2">
              Discover the Power of Our Products
            </h2>
            <p className="thq-body-large">
              <span>Experience the Difference with Our Premium Cabinets</span>
              <br></br>
              <span>
                At ASM MILLWORK, we blend exceptional craftsmanship with
                innovative design to create cabinets that transform your living
                spaces. Our commitment to quality and customer satisfaction sets
                us apart in the industry.
              </span>
            </p>
            <div className="steps-actions">
              <a
                href="tel:+14375804280"
                className="steps-button thq-button-filled thq-button-animated"
              >
                <span className="thq-body-small">Get free quote</span>
              </a>
            </div>
          </div>
          <div className="steps-container2">
            <div className="steps-container3 thq-card">
              <h2 className="thq-heading-2">{props.step1Title}</h2>
              <span className="steps-text07 thq-body-small">
                {props.step1Description}
              </span>
              <label className="steps-text08 thq-heading-3">01</label>
            </div>
            <div className="steps-container4 thq-card">
              <h2 className="thq-heading-2">{props.step2Title}</h2>
              <span className="steps-text10 thq-body-small">
                {props.step2Description}
              </span>
              <label className="steps-text11 thq-heading-3">02</label>
            </div>
            <div className="steps-container5 thq-card">
              <h2 className="thq-heading-2">{props.step3Title}</h2>
              <span className="steps-text13 thq-body-small">
                {props.step3Description}
              </span>
              <label className="steps-text14 thq-heading-3">03</label>
            </div>
            <div className="steps-container6 thq-card">
              <h2 className="thq-heading-2">{props.step4Title}</h2>
              <span className="steps-text16 thq-body-small">
                {props.step4Description}
              </span>
              <label className="steps-text17 thq-heading-3">04</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps.defaultProps = {
  step4Description:
    'Seamless Installation for a Perfect Fit Our professional installers will ensure your custom cabinets are fitted with precision and care, guaranteeing a flawless finish.',
  step3Description:
    'Expert Craftsmanship You Can Trust Our skilled craftsmen will meticulously construct your cabinets using high-quality materials and precise techniques, ensuring durability and beauty.',
  step2Title: 'Custom Design',
  step2Description:
    'Tailored Custom Designs Just for You Our team will design cabinets that are perfectly suited to your space, style, and functionality needs.',
  step4Title: 'Installation',
  step1Description:
    "Begin Your Journey with a Personalized Consultation Meet with our expert cabinet makers to discuss your project requirements and design preferences. Let's bring your vision to life.",
  step3Title: 'Craftsmanship',
  step1Title: 'Initial Consultation',
}

Steps.propTypes = {
  step4Description: PropTypes.string,
  step3Description: PropTypes.string,
  step2Title: PropTypes.string,
  step2Description: PropTypes.string,
  step4Title: PropTypes.string,
  step1Description: PropTypes.string,
  step3Title: PropTypes.string,
  step1Title: PropTypes.string,
}

export default Steps
